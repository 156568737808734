import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faUser as fortawesomefreesolidsvgicons_faUser } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUser)

    import  { faInfoCircle as fortawesomefreesolidsvgicons_faInfoCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faInfoCircle)

    import  { faCheckCircle as fortawesomefreesolidsvgicons_faCheckCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheckCircle)

    import  { faCheck as fortawesomefreesolidsvgicons_faCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheck)

    import  { faCoins as fortawesomefreesolidsvgicons_faCoins } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCoins)

    import  { faSearch as fortawesomefreesolidsvgicons_faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSearch)

    import  { faExternalLinkAlt as fortawesomefreesolidsvgicons_faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExternalLinkAlt)

    import  { faArrowLeft as fortawesomefreesolidsvgicons_faArrowLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowLeft)

    import  { faArrowRight as fortawesomefreesolidsvgicons_faArrowRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowRight)

    import  { faPlay as fortawesomefreesolidsvgicons_faPlay } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlay)

    import  { faPause as fortawesomefreesolidsvgicons_faPause } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPause)

    import  { faClock as fortawesomefreesolidsvgicons_faClock } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faClock)

    import  { faCircle as fortawesomefreesolidsvgicons_faCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircle)

    import  { faSignInAlt as fortawesomefreesolidsvgicons_faSignInAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSignInAlt)

    import  { faSignOutAlt as fortawesomefreesolidsvgicons_faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSignOutAlt)

    import  { faUserPlus as fortawesomefreesolidsvgicons_faUserPlus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUserPlus)

    import  { faSort as fortawesomefreesolidsvgicons_faSort } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSort)

    import  { faSortUp as fortawesomefreesolidsvgicons_faSortUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSortUp)

    import  { faSortDown as fortawesomefreesolidsvgicons_faSortDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSortDown)

    import  { faTimes as fortawesomefreesolidsvgicons_faTimes } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTimes)

    import  { faTimesCircle as fortawesomefreesolidsvgicons_faTimesCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTimesCircle)

    import  { faStar as fortawesomefreesolidsvgicons_faStar } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStar)

    import  { faStarHalf as fortawesomefreesolidsvgicons_faStarHalf } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStarHalf)

    import  { faStarHalfAlt as fortawesomefreesolidsvgicons_faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStarHalfAlt)

    import  { faHeart as fortawesomefreesolidsvgicons_faHeart } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHeart)

    import  { faEdit as fortawesomefreesolidsvgicons_faEdit } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faEdit)

    import  { faSyncAlt as fortawesomefreesolidsvgicons_faSyncAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSyncAlt)

    import  { faArrowCircleLeft as fortawesomefreesolidsvgicons_faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowCircleLeft)

    import  { faCaretDown as fortawesomefreesolidsvgicons_faCaretDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretDown)

    import  { faCaretUp as fortawesomefreesolidsvgicons_faCaretUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretUp)

    import  { faGift as fortawesomefreesolidsvgicons_faGift } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faGift)

    import  { faMobile as fortawesomefreesolidsvgicons_faMobile } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMobile)

    import  { faShoppingBag as fortawesomefreesolidsvgicons_faShoppingBag } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faShoppingBag)

    import  { faSync as fortawesomefreesolidsvgicons_faSync } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSync)

    import  { faInfinity as fortawesomefreesolidsvgicons_faInfinity } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faInfinity)

    import  { faPen as fortawesomefreesolidsvgicons_faPen } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPen)

    import  { faPenToSquare as fortawesomefreesolidsvgicons_faPenToSquare } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPenToSquare)

    import  { faCreditCard as fortawesomefreesolidsvgicons_faCreditCard } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCreditCard)

    import  { faGithub as fortawesomefreebrandssvgicons_faGithub } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faGithub)

    import  { faTwitter as fortawesomefreebrandssvgicons_faTwitter } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faTwitter)

    import  { faInstagram as fortawesomefreebrandssvgicons_faInstagram } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faInstagram)

    import  { faFacebookF as fortawesomefreebrandssvgicons_faFacebookF } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faFacebookF)

    import  { faIdeal as fortawesomefreebrandssvgicons_faIdeal } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faIdeal)

    import  { faXTwitter as fortawesomefreebrandssvgicons_faXTwitter } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faXTwitter)

    import  { faCopyright as fortawesomefreeregularsvgicons_faCopyright } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCopyright)

    import  { faCircle as fortawesomefreeregularsvgicons_faCircle } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCircle)

    import  { faCircleXmark as fortawesomefreeregularsvgicons_faCircleXmark } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCircleXmark)

    import  { faStar as fortawesomefreeregularsvgicons_faStar } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faStar)

    import  { faHeart as fortawesomefreeregularsvgicons_faHeart } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faHeart)

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)
